<template>
  <div class="layout-top-panel">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.layout-top-panel {
  width: 100%;
  background: white;
}
</style>