<template>
    <div class="side-menu__item">
      <div class="nav">
        <img src="/assets/navigation/menu_side_nav_item.png" alt="nav icon">
      </div>
      <div class="icon">
        <slot name="icon"></slot>
      </div>
      <div class="text">
        <slot name="text"></slot>
      </div>
      <div class="info">
        <slot name="info"></slot>
      </div>
      <div class="notice">
        <slot name="notice"></slot>
      </div>
    </div>
</template>

<style scoped lang="scss">
.nav img {
  opacity: 0;
}
.active .nav img {
  opacity: 1;
}
.active .icon {
  color: #00AEE8;
}
.side-menu__item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  align-items: center;
}
.nav {
  width: 3px;
  height: 40px;
  display: flex;
  padding-top: 6px;
  padding-bottom: 8px;
  cursor: pointer;
}
.icon {
  padding-left: 20px;
  padding-top: 6px;
  cursor: pointer;
}
.text {
  padding-left: 12px;
  font-family: Nunito Sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  flex-grow: 1;
  cursor: pointer;
}
.active .text {
  color: #00AEE8;
}
.info {
  justify-self: flex-end;
  font-family: Nunito Sans;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding-right: 24px;
  cursor: pointer;
}
.notice {
  justify-self: flex-start;
  font-family: Nunito Sans;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding-right: 24px;
  cursor: pointer;
}
</style>
<script setup lang="ts">
</script>
