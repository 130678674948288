import { defineStore } from 'pinia';
import { type Advert } from '~/interfaces/advert-studio';

interface AdvertModuleState {
  current: {
    id: number | null;
  };
  adverts: Advert[];
}

export const useAdvertStore = defineStore('advert', {
  state: (): AdvertModuleState => ({
    current: {
      id: null,
    },
    adverts: [],
  }),
  actions: {
    setAdverts(value: Advert[]) {
      this.adverts = value;
    },
    setCurrentAdvertId(value: number | null) {
      this.current.id = value;
    },
  },
});
