<template>
    <div class="layout-left-panel hover">
        <div class="layout-left-panel__box">
            <div class="logo-box">
                <slot>PublisherSocialJetLogo</slot>
            </div>
            <div class="menu-box">
                <slot name="menu">Menu</slot>
            </div>
            <div class="links-box">
                <slot name="links">Links</slot>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.layout-left-panel {
  position: fixed;
    min-width: 65px;
    width: 65px;
    max-width: 65px;
    background: white;
    box-shadow: 2px 0 25px 0 #3d4c6d14;
    height: 100vh;
    overflow: hidden;
    z-index: 100;
    &__box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-width: 265px;
        width: 265px;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);

      & .links-box {
        position: relative;
        height: 100%;
      }
    }
}

.logo-box {
    opacity: 0;
}

:deep(.side-menu__item .text) {
    opacity: 0;
}

:deep(.side-menu__item .info) {
    opacity: 0;
}

:deep(.side-menu__separator) {
    opacity: 0;
}

:deep(.links-container__item-text) {
  display: none;
}
:deep(.links-container__item) {
  width: 40px;
}
:deep(.links-container){
  @apply px-3;
}

.layout-left-panel:hover {
    overflow: visible;
    & .layout-left-panel__box {
        background-color: rgba(255, 255, 255, 1);
    }
    & .logo-box {
        opacity: 1;
    }
    & :deep(.side-menu__item .info) {
        opacity: 1;
    }
    & :deep(.side-menu__item .text) {
        opacity: 1;
    }
    :deep(.side-menu__separator) {
        opacity: 1;
    }
    :deep(.links-container__item-text) {
      display: inline-block;
    }
    :deep(.links-container__item) {
      width: 100%;
    }
    :deep(.links-container){
      @apply px-5;
    }
}

.logo-box {
    box-shadow: 2px 0 25px 0 #3d4c6d14;
}

.menu-box {
}
</style>
