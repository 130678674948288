<template>
  <div class="menu-logo-box">
    <PublisherMenuLogo></PublisherMenuLogo>
  </div>
</template>

<style lang="scss">
.menu-logo-box {
  border-bottom: solid 1px #D8F2FA;
  height: 71px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 24px;
}
</style>
