<template>
  <div class="app-wrapper" ref="wrapper">
    <div class="vertical-layout">
      <SizeWidth :box="wrapper" :minWidth="600" :maxWidth="10000" style="z-index: 100;">
        <PublisherMenuPanelSide>
            <PublisherMenuSocialJet />
            <template #menu>
              <PublisherMenuSide />
            </template>
            <template #links>
              <PublisherMenuLinks />
            </template>
        </PublisherMenuPanelSide>
      </SizeWidth>
        <div class="page-with-top-panel">
          <PublisherMenuPanelTop>
            <div class="gorizontal-menu flex-center justify-between">
              <div>{{ route.name }}</div>
              <SizeWidth :box="wrapper" :minWidth="0" :maxWidth="599" classes="!w-full">
                <div class="!w-full h-[60px] flex items-center justify-between">  
                  <div>
                    <img src="/public/assets/img/svg/mobile_menu.svg" alt="mobile_menu" class="cursor-pointer hover:opacity-50" />
                  </div>
                  <div>
                    <NuxtLink to="/publisher">
                      <img src="/public/assets/img/svg/mobile_logo.svg" alt="logo" class="cursor-pointer h-10" />
                    </NuxtLink>
                  </div>
                  <div class="flex items-center justify-end gap-3">
                    <div class="mt-2 mr-1"><i class="fi fi-sr-credit-card text-[25px] text-[#A1A1A1] mt-2"></i></div>
                    <div><Icon name="solar:bell-bold" width="30" height="30" size="30" class="text-[#A1A1A1] !w-[30px] mt-1" /></div>
                    <div><img src="/assets/img/user.png" alt="logo" class="rounded-full w-[40px] h-[40px]"/></div>
                  </div>
                </div>
              </SizeWidth>
              <SizeWidth :box="wrapper" :minWidth="600" :maxWidth="10000">
              <div class="flex align-center justify-between">
                <template v-if="userModule.id">
                  <div class="text-[#A1A1A1] font-bold border-l h-14 border-l-[#d8f2fa] flex items-center justify-center px-[24px]">
                    <i class="fi fi-sr-credit-card text-xl mr-2 mt-2"></i>
                    <span>1 252₽</span>
                  </div>
                  <div class="border-l border-l-[#d8f2fa] h-14 flex items-center justify-center px-[24px]">
                    <Icon name="solar:bell-bold" color="#efefef" width="20" height="20" size="25" class="text-[#A1A1A1]" />
                  </div>
                  <div class="flex items-center border-l border-l-[#d8f2fa] h-14 flex items-center justify-center px-[24px]">
                    <img src="/assets/img/user.png" alt="logo" class="rounded-full mr-2"/>
                    <span class="text-[#A1A1A1] font-bold">{{ userName || telegram || email || 'Имя пользователя' }}</span>
                    <Icon name="fa:chevron-down" width="10" height="10" class="ml-2 cursor-pointer font-[200] text-[10px] text-[#A1A1A1]" />
                  </div>

                </template>
                <NuxtLink v-else to="/">
                  <UIButtonSecondary
                     class="gorizontal-menu__aside-enter"
                    type="button"
                    >
                    Войти
                  </UIButtonSecondary>
                </NuxtLink>
              </div>
            </SizeWidth>
            </div>
          </PublisherMenuPanelTop>
          <div class="page-self">
            <!-- основной слот для страницы -->
            <slot />
          </div>
        </div>
    </div>

    <UIOverlay :view-overlay="true" />
      <ElDialog
        v-model="userModule.loginDialogVisible"
        modal-class="authorize-modal"
        width="min(500px,85vw)"
      >
        <LoginScreen/>
      </ElDialog>
  </div>
</template>
<script setup lang="ts">
  import { ref, onMounted } from 'vue';
  import { ElDialog } from 'element-plus';
  import { IconColor } from '~/interfaces/publisher';
  import { usePublisherStore } from '~/store/PublisherModule';
  import { useAppStore } from '~/store/AppModule';
  import { useChannelStore } from '~/store/ChannelModule';
  import { useAdvertContentStore } from '~/store/AdvertContentModule';
  import { useAdvertStore } from '~/store/AdvertModule';
  import { useRoute } from 'vue-router';

  const route = useRoute();

  // Stores
  const userModule = usePublisherStore();
  const channelModule = useChannelStore();
  const advertContentModule = useAdvertContentStore();
  const appModule = useAppStore();
  const advertModule = useAdvertStore();

  // Reference to a DOM element
  const wrapper = ref<HTMLElement | null>(null);

  // Запросить токен для пользователя
  onMounted(() => {
    userModule.requestAuthKey();
  });

  const userName = computed(() => userModule.name ?? null);
  const telegram = computed(() => userModule.telegram ?? null);
  const email = computed(() => userModule.email ?? null);



  // Make stores available in the global window object (not recommended in production)
  window.userModule = userModule;
  window.channelModule = channelModule;
  window.advertContentModule = advertContentModule;
  window.appModule = appModule;
  window.advertModule = advertModule;
</script>

<style lang="scss">
.app-wrapper {
  height: 100%;
}

.vertical-layout {
  display: flex;
  flex-direction: row;
}

.page-with-top-panel {
  width: 100%;
  flex-grow: 1;
}

.gorizontal-menu,
.vertical-menu {
  background: $white;
}

.el-menu + .screen-wrapper {
  margin-left: 70px;

  @media (max-width: $small-width) {
    margin-left: 0;
  }
}

.gorizontal-menu {
  @apply w-full pr-4 pl-2 z-50;
  height: 70px;

  &__aside {
    @apply h-full justify-end;

    &-user {
      width: fit-content;
      color: $light-gray;
      font-weight: 500;
    }

    &-notifications {
      width: fit-content;
      height: 100%;
      padding-left: clamp(20px, 2vw, 24px);
      padding-right: clamp(20px, 2vw, 24px);
      border-left: 1px solid rgba($main, 0.3);
      // border-right: 1px solid rgba($main, 0.3);

      @media (max-width: $small-width) {
        border: none;
      }
    }
  }

  &__button {
    cursor: pointer;
    margin: 6px 0px;
    width: 36px;
    height: 36px;
    border-radius: 11px;
    border: 1px solid rgba($main, 0.2);
    flex-direction: column;
    gap: 5px;
    padding-left: 8px;
    padding-right: 8px;

    > div {
      width: 100%;
      height: 2px;
      background: $main;
      border-radius: 1px;
    }
  }
}

.gorizontal-menu .gorizontal-menu__button {
  display: none;
}

@media (max-width: $small-width) {
  .app-wrapper .vertical-menu.vertical-menu.el-menu--collapse {
    border-right: none;

    &:not(.horizontal-collapse-transition) {
      transform: translateX(-300px);
    }

    img {
      display: none;
    }
  }

  .gorizontal-menu .gorizontal-menu__button {
    display: flex;

    + a {
      z-index: 1;
    }
  }

  .gorizontal-menu {
    justify-content: space-between;
  }

  @keyframes close {
    to {
      transform: translateX(-300px);
    }
  }

  .vertical-menu:not(.el-menu--collapse).v-leave-active {
    animation: close 0.4s ease-in;
  }
}

button.gorizontal-menu__aside-enter {
  @apply px-3;
  max-width: min(35vw, 8rem);
}
</style>
